.back-button {
    font-size: 14px;
    text-decoration: none;
    color: var(--dui-color-red-500);
    cursor: pointer;
    align-items: center;
    display: inline-flex;

    svg {
        fill: var(--dui-color-red-500);
    }
}
