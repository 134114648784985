.progress-loader {
  margin: auto;
  width: 96px;
  height: 96px;
}

.progress-loader__text {
  width: 200px;
  justify-content: center;
  display: flex;
}
