body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Delivery, Verdana, sans-serif;
    font-size: 16px;
}

/*  Bold */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_Bd.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

/*  Bold Italic */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_BdIt.woff2) format("woff2");
    font-weight: 700;
    font-style: italic;
}

/*  Condensed Black */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_CdBlk.woff2) format("woff2");
    font-weight: 800;
    font-stretch: condensed;
    font-style: normal;
}

/*  Condensed Light */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_CdLt.woff2) format("woff2");
    font-weight: 200;
    font-stretch: condensed;
    font-style: normal;
}

/*  Italic */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_It.woff2) format("woff2");
    font-weight: 400;
    font-style: italic;
}

/*  Light */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_Lt.woff2) format("woff2");
    font-weight: 100;
    font-style: normal;
}

/*  Light italic */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_LtIt.woff2) format("woff2");
    font-weight: 100;
    font-style: italic;
}

/*  Regular */
@font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(./../../fonts/Delivery_W_Rg.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}
