.login__main {
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    column-gap: normal !important;

    .login__back-button-form {
        position: absolute;
        left: 20px;
        top: 50px;
    }

    .login__side-image {
        flex: 3;

        .login__side-image-styles {
            flex: 1;
            display: flex;
            width: 100%;
            height: 100%;
            column-gap: normal;
            background-image: url("./../../assets/images/login_plane.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left;
        }

        .login__back-button {
            position: absolute;
            top: 5%;
            left: 3%;
        }
    }

    .login__container-form {
        flex: 4;
        padding: 0 15px;
        background: linear-gradient(180deg, #ffcc00 0%, #ffcc00 30%, #ffe57f 79%, #fff0b2 100%);

        .login__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            .login__dhl-aviation {
                height: 84px;
                max-height: 84px;
            }

            .login__title {
                height: 42px;

                &-welcome {
                    margin-top: 35px;
                    margin-bottom: 10px;
                }

                &-payment {
                    margin-bottom: 10px;
                }
            }

            .login__form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .login__button {
                    margin-top: 16px;
                    height: 41px;
                }
            }

            .login__forgot-password-link {
                text-decoration: none;
                margin-top: 25px;
            }

            .login__button-logout {
                margin-top: 10px;
                text-decoration: underline;
                font-size: medium;
                font-weight: 800;
                font-stretch: condensed;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .login__side-image {
        display: none;
    }
}

@media screen and (max-width: 1031px) {
    .login__line-break {
        display: none;
    }
}
