.search-customer__main {
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    column-gap: normal !important;

    .search-customer__side-image {
        flex: 3;

        .search-customer__side-image-styles {
            display: flex;
            height: 100%;
            column-gap: normal;
            background-image: url("./../../assets/images/search_plane.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .search-customer__container-form {
        flex: 4;
        padding: 0 15px;
        background: linear-gradient(180deg, #ffcc00 0%, #ffcc00 30%, #ffe57f 79%, #fff0b2 100%);

        .search-customer__wrapper {
            height: 100%;

            .search-customer__dhl-aviation {
                height: 84px;
                max-height: 84px;
            }

            .search-customer__title {
                height: 42px;

                &-welcome {
                    margin-top: 35px;
                    margin-bottom: 10px;
                }

                &-payment {
                    margin-bottom: 10px;
                }
            }

            .search-customer__form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .search-customer__search-button {
                    margin-top: 16px;
                    height: 41px;
                }
            }
        }
    }
}

.search-customer__loader {
    display: flex;
    height: calc(100vh - 150px);
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .search-customer__side-image {
        display: none;
    }
}

@media screen and (max-width: 1031px) {
    .search-customer__line-break {
        display: none;
    }
}

@media screen and (min-width: 1920px) {
    .search-customer__main {
        max-width: 100% !important;
    }
}
