.shipment-edit__modal {
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    overflow-y: auto;
}

.shipment_edit__main {
    flex-grow: 1;

    .shipment__container {
        flex-grow: 1;

        .shipment__side-bar {
            .shipment__side-bar-card {
                display: flex;
                flex-direction: column;
                height: 100%;
                background-color: var(--dui-color-gray-50);
                padding: 40px 20px;

                .shipment__side-bar-data {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }

                .shipment__side-bar-back-button-link {
                    display: none;
                }
            }
        }

        .shipment__container-table {
            .shipment__table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 55px;
                padding-bottom: 46px;

                .invoice-edit__cancel-process {
                    color: var(--dui-color-red-500);
                    padding-left: 0px;
                    font-weight: 400;
                    i svg {
                        fill: var(--dui-color-red-500);
                    }
                }
            }

            .shipment__edit-title {
                color: var(--dui-color-red-500);
            }

            .shipment__invoice-accordion {
                margin-top: 8px;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 0px;

                .shipment__accordion-inner {
                    margin-bottom: 16px;
                }

                .firstChild {
                    border-width: 1px;
                    margin-bottom: 16px;
                    padding: 0px;

                    &:focus-within {
                        border-left: 0px;
                        border-right: 0px;
                    }

                    div[aria-expanded="false"] {
                        i.sc-httYss svg {
                            transform: rotate(180deg);
                        }
                    }

                    div {
                        i.sc-httYss svg {
                            transform: rotate(270deg);
                        }
                    }
                }

                div[id^="invoice_title"] {
                    &:focus {
                        border-left: none;
                        border-right: none;
                    }
                }

                .AccordionHeadline {
                    margin-left: 0px;
                }

                i {
                    width: 24px;
                    height: 24px;
                }

                .accordion-headline-section {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .shipment__invoice-delete {
                        padding: 10px 0px;
                        color: rgba(0, 0, 0, 0.9);
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 24px;

                        &:focus {
                            box-shadow: none;
                        }
                        &:active {
                            box-shadow: none;
                        }

                        i svg {
                            fill: var(--dui-color-red-500);
                        }
                    }

                    span {
                        white-space: nowrap;
                    }
                }

                dd.expanded {
                    padding: 0px 0px 16px 0px;
                    border-bottom: 0px;
                }
            }

            .shipment__table-wrapper {
                border-top: solid 3px #000000;
                border-bottom: solid 3px #000000;
                padding-bottom: 8px;
                padding-left: 26px;
            }

            .shipment__table {
                .shipment__table-input-valid {
                    border: 1px solid var(--dui-color-red-500 !important);
                    border-radius: 2px;
                }

                .shipment__table-caption {
                    caption-side: top;

                    .shipment__table-pdf-dhl {
                        display: flex;
                        flex-direction: row;

                        svg {
                            fill: var(--dui-color-red-500);
                        }

                        .shipment__table-title {
                            display: flex;
                            flex-grow: 1;
                            align-items: center;
                        }
                    }
                }

                .shipment__table-head {
                    tr {
                        border-bottom: 0px;
                    }
                    th {
                        border-bottom: solid 1px #cccccc;
                        border-left: solid 6px #ffffff;
                        border-right: solid 6px #ffffff;
                    }
                    th.remove-btn_cell-head {
                        border: 0px;
                    }
                }

                tbody {
                    tr {
                        border-bottom: 0px;
                    }
                    td {
                        border-bottom: solid 1px #cccccc;
                        border-left: solid 6px #ffffff;
                        border-right: solid 6px #ffffff;
                    }
                }

                td.total-net_cell {
                    padding: 4px 0px;
                    border: 0px;
                }

                .total-net__wrap {
                    padding: 9px 12px;
                    border: 1px solid rgba(0, 0, 0, 0.45);
                    border-radius: 4px;
                    position: relative;

                    &::before {
                        content: "$";
                        position: absolute;
                        bottom: 10px;
                    }

                    .total-net__amount-text {
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 12px;
                        color: rgba(0, 0, 0, 0.9);
                        margin-bottom: 0px;
                    }

                    .shipment__table-input {
                        width: 100%;
                        border: none;
                        margin-left: 6px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input:focus-visible {
                        outline: none;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }

                .remove-btn_cell {
                    border: 0px;
                    padding: 4px 0px 4px 8px;
                }

                .shipment__table-footer {
                    border-top: 0px;
                }
            }
        }

        .shipment__footer {
            display: flex;
            flex-direction: column;

            .shipment__footer-divider {
                border: 1px solid var(--dui-color-gray-300);
                margin-bottom: 55px;
            }

            .shipment__footer-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                min-height: 85px;

                .shipment__sign-up-button {
                    text-decoration: none;
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__sign-in-button {
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__or {
                    text-align: center;
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__continue-button {
                    width: auto;
                    flex-direction: column;

                    .shipment__continue-button-text {
                        top: -30px;
                        position: absolute;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1031px) {
    .shipment_edit__main {
        .shipment__container {
            .shipment__footer {
                .shipment__footer-buttons {
                    .shipment__continue-button {
                        .shipment__continue-button-text {
                            top: -40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .shipment_edit__main {
        height: auto;

        .shipment__container {
            .shipment__side-bar {
                .shipment__side-bar-card {
                    flex-direction: column-reverse;

                    .shipment__side-bar-back-button {
                        display: none;
                    }

                    .shipment__side-bar-back-button-link {
                        display: block;
                    }
                }
            }

            .shipment__container-table {
                padding-right: 0px;
            }

            .shipment__footer {
                .shipment__footer-buttons {
                    flex-direction: column;
                    padding: 15px;

                    .shipment__sign-up-button {
                        width: 100%;
                        margin-right: 0;

                        button {
                            width: 100%;
                        }
                    }

                    .shipment__sign-in-button {
                        width: 100%;
                        margin-right: 0;
                    }

                    .shipment__or {
                        width: 100%;
                        margin-right: 0;
                    }

                    .shipment__continue-button {
                        width: 100%;
                        margin-right: 0;

                        .shipment__continue-button-text {
                            top: -40px;
                            width: 100%;
                        }

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
