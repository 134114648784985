.summary {
    .sc-cKZGGw {
        svg {
            margin-left: 0 !important;
        }
    }
}

.summary__loading {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 50px;
}
