.header__container {
    display: flex;
    height: 40px;
    justify-content: flex-end;

    .header__logo {
        height: 24px;
        margin-top: 14px;
        margin-right: 20px;
    }
}
