button {
    div[role="status"] {
        height: 20px;
        width: 20px;
    }
}

.ighNPq:focus > span {
    outline: none !important;
    padding: 0 !important;
}

.bKkEHE {
    display: flex;
    min-height: 100px;

    &:focus {
        border: 0px solid black !important;
        padding: 0px !important;
    }

    .dxTsYX .hHVmWK {
        align-self: center;
        margin-top: auto;
    }
}

.PL-Modal__Content:focus {
    outline: none !important;
}

ul#street-auto-complete {
    z-index: 1000;
}
