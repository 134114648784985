.layout__main {
  display: flex !important;
  flex-direction: column;
  height: 100vh;

  .layout__body {
    height: calc(100vh - 85px);
    display: flex !important;
    flex-direction: column;
  }
}
