.payment-success {
    flex-flow: column;
    height: 100vh;

    .payment-success__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 460px;
        padding: 0 30px;

        .payment-success__icon {
            max-height: 72px;
            max-width: 72px;
            margin-right: 30px;
        }

        .payment-success__description {
            margin: 15px 0 15px 0 !important;
        }
    }
}

@media screen and (max-width: 400px) {
    .payment-success {
        .payment-success__container {
            flex-direction: column;
            justify-content: center;
        }

        .payment-success__icon {
            margin-bottom: 20px;
        }
    }
}
