.input__dhl {
    margin-top: 16px;

    div[role="alert"] {
        i {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.input__dhl-mask {
    margin-top: 16px;
    width: 100%;

    div[role="alert"] {
        i {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
