.shipment__main {
    flex-grow: 1;

    .shipment__container {
        flex-grow: 1;

        .shipment__side-bar {
            .shipment__side-bar-card {
                display: flex;
                flex-direction: column;
                height: 100%;
                background-color: var(--dui-color-gray-50);
                padding: 40px 20px;

                .shipment__side-bar-data {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }

                .shipment__side-bar-back-button-link {
                    display: none;
                }
            }
        }

        .shipment__container-table {
            padding-right: 50px;

            .shipment__table-header {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 15px;
                padding-bottom: 40px;

                .shipment__table-info {
                    display: flex;
                    flex-direction: column;
                    margin: 0 20px;
                }
            }

            .shipment__edit-title {
                color: var(--dui-color-red-500);
            }

            .shipment__table-wrapper {
                border-top: solid 3px #000000;
                border-bottom: solid 3px #000000;
                padding-bottom: 8px;
                padding-left: 26px;
            }

            .shipment__table-container {
                overflow-x: scroll;

                .shipment__table {
                    margin-bottom: 50px;
                    table-layout: initial;
                    border-bottom: solid 3px #b2b2b2;

                    .shipment__table-caption {
                        caption-side: top;

                        .shipment__table-pdf-dhl {
                            display: flex;
                            flex-direction: row;

                            svg {
                                fill: var(--dui-color-red-500);
                            }

                            .shipment__table-title {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                            }
                        }
                    }

                    .shipment__table-input {
                        width: 100%;
                        border: none;
                    }

                    .shipment__table-input-valid {
                        border: 1px solid var(--dui-color-red-500);
                        border-radius: 2px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }

                    .shipment__table-input-valid {
                        border: 1px solid var(--dui-color-red-500 !important);
                        border-radius: 2px;
                    }

                    .shipment__table-caption {
                        caption-side: top;

                        .shipment__table-pdf-dhl {
                            display: flex;
                            flex-direction: row;

                            svg {
                                fill: var(--dui-color-red-500);
                            }

                            .shipment__table-title {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                            }
                        }
                    }

                    .shipment__table-head {
                        tr {
                            border-bottom: 0px;
                        }
                        th {
                            border-bottom: solid 1px #cccccc;
                            border-left: solid 6px #ffffff;
                            border-right: solid 6px #ffffff;
                        }
                        th.remove-btn_cell-head {
                            border: 0px;
                        }
                    }

                    tbody {
                        tr {
                            border-bottom: 0px;
                        }
                        td {
                            border-bottom: solid 1px #cccccc;
                            border-left: solid 6px #ffffff;
                            border-right: solid 6px #ffffff;
                        }
                    }

                    td.total-net_cell {
                        padding: 4px 0px;
                        border: 0px;
                    }

                    .total-net__wrap {
                        padding: 9px 12px;
                        border: 1px solid rgba(0, 0, 0, 0.45);
                        border-radius: 4px;
                        position: relative;

                        &::before {
                            content: "$";
                            position: absolute;
                            bottom: 10px;
                        }

                        .total-net__amount-text {
                            font-size: 11px;
                            letter-spacing: 0;
                            line-height: 12px;
                            color: rgba(0, 0, 0, 0.9);
                            margin-bottom: 0px;
                        }

                        .shipment__table-input {
                            width: 100%;
                            border: none;
                            margin-left: 6px;
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        input:focus-visible {
                            outline: none;
                        }

                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }

                    .remove-btn_cell {
                        border: 0px;
                        padding: 4px 0px 4px 8px;
                    }

                    .shipment__table-footer {
                        border-top: 0px;
                    }
                }
            }
        }

        .shipment__footer {
            display: flex;
            flex-direction: column;

            .shipment__footer-divider {
                border: 1px solid var(--dui-color-gray-300);
                margin-bottom: 55px;
            }

            .shipment__footer-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                min-height: 85px;

                .shipment__sign-up-button {
                    text-decoration: none;
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__sign-in-button {
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__or {
                    text-align: center;
                    width: auto;
                    margin-right: 1rem !important;
                }

                .shipment__continue-button {
                    width: auto;
                    flex-direction: column;

                    .shipment__continue-button-text {
                        top: -30px;
                        position: absolute;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1031px) {
    .shipment__main {
        .shipment__container {
            .shipment__footer {
                .shipment__footer-buttons {
                    .shipment__continue-button {
                        .shipment__continue-button-text {
                            top: -40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .shipment__main {
        height: auto;

        .shipment__container {
            .shipment__side-bar {
                .shipment__side-bar-card {
                    flex-direction: column-reverse;

                    .shipment__side-bar-back-button {
                        display: none;
                    }

                    .shipment__side-bar-back-button-link {
                        display: block;
                    }
                }
            }

            .shipment__container-table {
                padding-right: 0px;
            }

            .shipment__footer {
                .shipment__footer-buttons {
                    flex-direction: column;
                    padding: 15px;

                    .shipment__sign-up-button {
                        width: 100%;
                        margin-right: 0;

                        button {
                            width: 100%;
                        }
                    }

                    .shipment__sign-in-button {
                        width: 100%;
                        margin-right: 0;
                    }

                    .shipment__or {
                        width: 100%;
                        margin-right: 0;
                    }

                    .shipment__continue-button {
                        width: 100%;
                        margin-right: 0;

                        .shipment__continue-button-text {
                            top: -40px;
                            width: 100%;
                        }

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
