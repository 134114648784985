.navbar__main {
    position: relative;
    width: 100%;

    .navbar__logo {
        position: absolute;
        top: 50%;
        transform: translateY(-25%);
        right: 28px;
        max-height: 28px;
    }
}

@media screen and (min-width: 768px) {
    .navbar__main {
        .navbar__logo {
            max-height: 51px;
            transform: translateY(-50%);
        }
    }
}
