.PhoneInput.flag-enabled {
  position: relative;
  align-items: flex-start !important;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--dui-size-space-10x);

  .phone-input__flag-container {
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 40px;
    font-size: 14px;
  }

  .phone-input__container div:nth-child(2) {
    width: 100%;
  }

  .input__dhl-mask span:nth-child(2) {
    padding-left: 30px;
  }
}
