.search__main {
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: visible;

    .search__container-form {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 700px;
        padding: 0 40px;

        .search__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 60px;

            .search__form {
                width: 100%;

                .search__container-radios {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;

                    .search__radio {
                        flex: 1;
                        margin-right: 24px;
                        margin-top: 0px;
                    }

                    .search__radio-or {
                        flex: 1;
                        margin-top: 10px;
                        text-align: center;
                    }
                }

                .search__search-button {
                    margin-top: 16px;
                    height: 41px;
                }
            }
        }

        .search__back-button {
            cursor: pointer;
            margin-top: 2em;
        }
    }
}
