.credit-card-tab__container {
    .credit-card-tab__container-top {
        display: flex;
        align-items: center;

        .credit-card-tab__title {
            flex-grow: 1;
        }
    }

    .credit-card-tab__container-cards {
        .credit-card-tab__credit-card-row {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .credit-card-tab__radio-credit-card {
                margin-right: 24px;
            }

            .credit-card-tab__delete-card {
                display: flex;
                cursor: pointer;

                .credit-card-tab__icon-delete-card {
                    path {
                        fill: var(--dui-color-red-500);
                    }
                }
            }
        }
    }

    .credit-card-tab__container-cancel-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 23px;
    }

    .credit-card-tab__new-card-form {
        flex: 1;

        .credit-card-tab__add-button {
            margin-top: 32px;
        }
    }
}
