.global-modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 60vw;
    padding: 30px;

    &__icon {
        &-error {
            margin-right: 30px;
            width: 100px;
            min-width: 80px;
            path {
                fill: var(--dui-color-red-500);
            }
        }

        &-success {
            margin-right: 30px;
            width: 100px;
            min-width: 80px;
            path {
                fill: var(--dui-color-green-500);
            }
        }

        &-exception {
            margin-right: 30px;
            width: 100px;
            min-width: 80px;
            path {
                fill: var(--dui-color-red-500);
            }
        }
    }

    &__button-group {
        display: flex;
        justify-content: flex-end;
    }
}

.global-modal__container {
    max-height: none !important;
}

@media screen and (max-width: 1031px) {
    .global-modal {
        &__icon {
            &-error {
                min-width: 50px;
            }

            &-success {
                min-width: 50px;
            }

            &-exception {
                min-width: 50px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .global-modal {
        max-width: none;
        flex-direction: column;

        &__icon {
            &-error {
                min-width: auto;
            }

            &-success {
                min-width: auto;
            }

            &-exception {
                min-width: auto;
            }
        }
    }
}
