.cart__container-form {
    justify-content: flex-end;
}

.cart__summary {
    hr.solid {
        color: var(--dui-color-gray-500);
    }

    .cart__account {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;

        .cart__account-name {
            flex-grow: 1;
        }
    }

    .form-credit-notes {
        h5 {
            width: auto;
        }
    }

    .cart__summary-body {
        overflow: visible;

        .cart__invoice-number-section {
            .action-button-group {
                .action-button {
                    &:active,
                    &:focus {
                        box-shadow: none;
                    }
                }

                .invoice-edit-btn {
                    color: var(--dui-color-gray-600);
                }

                .invoice-delete-btn {
                    padding-right: 0px;
                }

                .line-vertical {
                    color: var(--dui-color-gray-600);
                }
            }
        }
    }
}
