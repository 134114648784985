.braintree-field-wrapper {
  position: relative;
  margin-top: 16px;
}

.braintree-hosted-fields {
  box-sizing: border-box;
  height: var(--dui-size-space-24x);
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: var(--dui-size-space-16x);
  width: 100%;
  border: solid 1px var(--dui-color-gray-500);
  border-radius: var(--dui-size-radius-md);
  transition: border-color var(--dui-duration-3x);
  outline: 0px;

    &-invalid, &.invalid {
      border: solid 1px var(--dui-color-red-500);
    }

    &:hover {
      border-color: var(--dui-color-black-500);
    }

    &.focused {
      border-color: var(--dui-color-black-500);
      box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px;
    }
}

.braintree-label {
  box-sizing: border-box;
  color: var(--dui-color-gray-600);
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-2xs);
  left: 0px;
  line-height: 1.15;
  opacity: 1;
  overflow: hidden;
  padding-left: var(--dui-size-space-7x);
  padding-right: var(--dui-size-space-16x);
  padding-top: var(--dui-size-space-4x);
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0px;
  transform: translateY(0px);
  transition: color var(--dui-duration-3x),transform var(--dui-duration-3x), font-size var(--dui-duration-3x),opacity var(--dui-duration-3x);
  white-space: nowrap;
  width: 100%;
}

.braintree-error {
  -webkit-box-align: center;
  align-items: center;
  animation: showerror var(--dui-duration-3x);
  color: var(--dui-color-red-500);
  display: flex;
  font-size: var(--dui-size-font-sm);
  margin-top: var(--dui-size-space-4x);

  svg {
    width: 18px;
    height: 18px;
    margin-right: var(--dui-size-space-2x);
  }
}

@keyframes showerror {
  from {
    transform: translateY(-10px) scale(0.9);
    opacity: 0;
  }

  to {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}