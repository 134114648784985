.form-guest {
    justify-content: flex-end;

    // .hHVmWK {
    //     max-height: 41px;
    // }
}

.form-guest__summary {
    hr.solid {
        color: var(--dui-color-gray-500);
    }
}

// @media screen and (max-width: 1031px) {
//     .form-guest {

//         .hHVmWK {
//             max-height: 45px;
//         }
//     }
// }

// @media screen and (max-width: 767px) {
//     .form-guest {

//         .hHVmWK {
//             max-height: 43px;
//         }
//     }
// }
