.paypal-tab__container {
  .paypal-tab__title {
    flex-grow: 1;
  }

  .paypal-tab__radio-paypal {
    margin-right: 24px;

    .paypal-tab__paypal-image {
      max-height: 35px;
    }
  }
}
