.popover__container-main {
  flex-direction: row;
  display: flex;
}

.popover__container {
  visibility: visible;
  height: 0;
}

.popover__container-absolute {
  visibility: hidden;
  height: 0;
}
