.error__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    .error__icon {
        height: 16px;
        margin-right: 5px;
        margin-top: 2px;

        path {
            fill: var(--dui-color-red-500);
        }
    }
}
